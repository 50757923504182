.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}





@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



/* For WebKit browsers (Chrome, Safari) */
::-webkit-scrollbar {
  width: 2px; /* Width of the scrollbar */
  height: 18px; /* Height of the horizontal scrollbar (if applicable) */
}

::-webkit-scrollbar-thumb {
  background: #2ba2c4; /* Color of the scrollbar handle */
  border-radius: 10px; /* Rounded corners for the scrollbar handle */
}

::-webkit-scrollbar-thumb:hover {
  background: #03b5fc; /* Color of the scrollbar handle on hover */
}

::-webkit-scrollbar-track {
  background: #636972; /* Color of the scrollbar track */
}