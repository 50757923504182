.styles__imageHeader--l6rLG {
  display: none !important;
}
.container .row.no-gutter {
  display: none !important;
}

.logo {
  height: 65px;
  width: 60.67px;
}
.upper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}
.upper-p {
  font-weight: bold;
  font-size: 24px;
  color: #ffffff;
}
.styles_ReactSwipeButton__2WML2 {
  z-index: 1;
}
.epaylogo {
  height: 86px;
  width: 219px;
}
.paragraph {
  font-weight: 600;
  font-size: 14px;
  color: #1f1f1f;
}

.form {
  width: 100%;
  /* padding: 30px; */
  /* margin-top: -30px; */
}
.form-control {
  width: 300px;
  height: 50px;
  padding: 10px;
  border: none;
  border-bottom: 1px solid #d1d1d1;
  padding: 2px 5px;
  outline: none;
  border-radius: 0%;
  margin-top: -15px;
}
.form-control:focus {
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.form-control::placeholder {
  /* color: #1F1F1F; */
  font-size: 14px;
  font-weight: bold;
}

.right-inner-addon {
  position: relative;
  margin-top: 12px;
}
.right-inner-addon input {
  padding-right: 35px !important;
}
.right-inner-addon img {
  position: absolute;
  right: 0px;
  padding: 12px 12px;
  /* pointer-events: none; */
  content: "";
  cursor: pointer;
}

.topHeader {
  padding: 15px;
  background-color: #6cf;
  /* width:340px; */
  height: 240px;
}
.secondDiv {
  background-color: white;
  border-radius: 32px 32px 0px 0px;
  margin-top: -32px;
  width: 350px;
}

.epayImg {
  padding: 10px;
  background-color: white;
  border-radius: 50%;
  height: 69px;
  width: 74px;
  margin-top: 30px;
}
.gAlogIn {
  cursor: pointer;
}

.bodykk {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: -webkit-translate(-50%, -50%);
  transform: -moz-translate(-50%, -50%);
  transform: -ms-translate(-50%, -50%);
  color: darkred;
}

.testloader {
  position: relative;
  display: grid;
  place-items: center;
  width: 80px;
  height: 80px;
}
.testloader span {
  position: absolute;
  border: 6px solid blue;
  border-radius: 50%;
  animation: ripple 1.8s ease-out infinite;
}
.testloader span:nth-child(2) {
  animation-delay: -0.6s;
}
@keyframes ripple {
  from {
    opacity: 1;
    width: 0;
    height: 0;
  }
  to {
    opacity: 0;
    width: 100%;
    height: 100%;
  }
}

.button {
  background-color: #6cf;
  border: none;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  width: 284px;
  height: 53px;
  border-radius: 25px;
  font-weight: bold;
  margin-top: 25px;
}
.content {
  margin: 10px auto;
  width: 275px;
  margin-top: 50px;
}

.btnSucceess {
  background-color: #6cf;
  border: 1px solid #6cf;
  border-radius: 50%;
  width: 45px;
  height: 45px;
}
h2 {
  color: #6cf;
}
.btnSucceessImage {
  width: 30px;
  height: 25px;
}

.content2 {
  margin: 10px auto;
  width: 275px;
  margin-top: 120px;
}
.content3 {
  margin: 10px auto;
  width: 275px;
  margin-top: 170px;
}
.or {
  text-align: center;
  font-size: 13px;
  /* background:
      linear-gradient(#000 0 0) left,
      linear-gradient(#000 0 0) right;
    background-size: 35% 1px;
    background-repeat: no-repeat; */
  font-weight: 500;
}
.icon {
  height: 20px;
  width: 20px;
}
.lowerdiv {
  justify-content: center;
  text-align: center;
  display: flex;
  align-items: center;
  gap: 30px;
}
.upperdiv {
  justify-content: space-between;
  text-align: center;
  display: flex;
  align-items: center;
  gap: 30px;
  margin-top: 15px;
}

.OTPBOX {
  margin: 2px;
  height: 35px;
  /* width: 50px; */
  border: none;
  /* border-radius: 5px; */
  text-align: center;
  font-family: arimo;
  font-size: 1.2rem;
  /* background: #eef2f3; */
  /* border: 1px solid grey; */
  /* margin: 20px; */
}
.INPUTSTYLE {
  height: 53px;
  width: 53px !important;
  margin-top: 40px;
  border-radius: 0;
  border-bottom: 1px solid !important;
  background: none;
  border-top: 0;
  border-left: 0;
  border-right: 0;
}
.INPUTSTYLESIGNUP {
  height: 40px;
  width: 53px !important;
  margin-top: 0;
  border-radius: 0;
  border-bottom: 1px solid !important;
  background: none;
  border-top: 0;
  border-left: 0;
  border-right: 0;
}
.resendOTP {
  font-size: 10px;
  margin-top: 10px;
  font-weight: bold;
  text-align: right;
  width: 100%;
  margin-bottom: 0;
  color: #6cf;
  text-decoration: underline;
}
.containers {
  display: flex;
  flex-flow: column;
  height: 100%;

  /* margin-top: 100px; */
}
.button2 {
  background-color: #16e3ff;
  border: none;
  cursor: pointer;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  /* cursor: pointer; */
  width: 284px;
  height: 53px;
  border-radius: 15px;
  font-weight: bold;
  margin-top: 85px;
}
.otp-input {
  height: 40px;
  border-radius: 4px;
  border: none;
}
.button3 {
  background-color: #16e3ff;
  border: none;
  cursor: pointer;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  /* cursor: pointer; */
  width: 284px;
  height: 53px;
  border-radius: 15px;
  font-weight: bold;
  margin-top: 25px;
}
.verification {
  justify-content: center;
  /* align-items: center; */
  text-align: center;
}
.retrybutton {
  background-color: #16e3ff;
  border: none;

  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  /* cursor: pointer; */
  width: 284px;
  height: 53px;
  border-radius: 15px;
  font-weight: bold;
  margin-top: 35px;
}
.verification {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.styles_rsbContainer__KXpXK {
  background: green;
}
.react-tel-input {
  background: #ffffff;
  margin-top: 15px;
  border-radius: 15px;
}
.react-tel-input .form-control {
  border: none !important;
  border-radius: 15px !important;
  margin-left: 50px !important;
}
.react-tel-input .selected-flag:before {
  background: #ffffff !important;
  border-radius: 15px !important;
}
.react-tel-input .selected-flag {
  border-radius: 15px !important;
}

.mainf {
  margin-top: 6%;
  background-color: white;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
}

.card {
  background: white;
  padding: 60px;
  border-radius: 4px;
  box-shadow: 0 2px 3px #c8d0d8;
  display: inline-block;
  margin: 0 auto;
}

.fail {
  color: #ff4747;
  font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
  font-weight: 900;
  font-size: 40px;
  margin-bottom: 10px;
}

.scc {
  color: #88b04b;
  font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
  font-weight: 900;
  font-size: 40px;
  margin-bottom: 10px;
}

.top {
  width: 300px;
}
